import { AxiosResponse } from 'axios'
import axios from 'axios'

const getPointsTotal = async (community_id: string): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/available_point`)

const createPoints = async (community_id: string, params: any): Promise<AxiosResponse> =>
  axios.post(`/communities/${community_id}/granted_points`, {
    ...params,
  })

const grantedPointsServices = {
  getPointsTotal,
  createPoints,
}

export default grantedPointsServices
